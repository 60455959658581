import React from 'react'

import PageWithLeftMenu from '../../components/PageWithLeftMenu'
import P from '../../components/P'
import SEO from '../../components/seo'

import items from './_items'

export default () => {
  return (
    <PageWithLeftMenu title="B.A.C.I." items={items}>
      <SEO
        title="BACI - sistem biserici independente"
        keywords={[`baci`, 'biserici independente', `fundația ebe`]}
      />
      <P style={{ textAlign: 'center' }}>
        <P>Sistemul de Educație Biblică prin Extensie</P>
        <P>Coordonator sistem B.A.C.I.</P>
        <P>
          <i>Mitică Ilieși</i>
        </P>
      </P>

      <P>
        Filosofia mea de viață se bazează pe investiții pe termen lung, iar cea
        mai înțeleaptă investiție este acea care se face în oameni, în
        credințele, virtuțile și caracterul lor, deoarece acestea sunt lucrurile
        pe care le vom avea și în veșnicie cu noi.
      </P>
      <P>
        Pasiunea mea este să ajut la maturizarea și perfecționarea slujitorilor
        lui Dumnezeu din România, atât din perspectiva vieții personale cât și
        din cea a deprinderilor necesare slujirii creștine.
      </P>
      <P>
        <b>Viziunea B.A.C.I.</b>
      </P>

      <P>
        O lucrare dezvoltată de formare a liderilor slujitori și ucenicizatori
        în cadrul bisericilor autonome din Romania
      </P>

      <P>
        <b>Nevoia</b>
      </P>
      <P>
        Bisericile autonome din Romania sunt conduse în general de lideri
        puternici, cu carismă personală, care au mare nevoie de echipe puternice
        care să-i secondeze și să-i ajute în administrarea și implementarea
        viziunii și misiunii bisericii locale.
      </P>

      <P>
        <b>Soluția (Obiective):</b>
      </P>
      <P ml={4}>
        <ol>
          <li>Consolidarea echipei naționale de coordonare a lucrării BACI.</li>
          <li>
            Formarea unei rețele de oameni de încredere în cadrul fiecărei
            federații de biserici autonome, care să implementeze formarea de
            lideri slujitori și ucenicizatori.
          </li>
          <li>
            Continuarea și dezvoltarea Școlii de Teologie și practică pastorală
            “Timotei”.
          </li>
        </ol>
      </P>
    </PageWithLeftMenu>
  )
}
